.FlipCard {
  background-color: red;
  padding: 35px;
  border-radius: 60px;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
  position: relative;
  transform-style: preserve-3d;
}

.FlipCard:hover {
  transform: rotateY(180deg);
}

.FlipCard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.FlipCard-image {
  width: 90%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 2rem;
}

.FlipCard-title {
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.FlipCard-text {
  font-size: 0.5rem;
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}


.FlipCard-content.back {
  transform: rotateY(180deg);
  background-color: black;
  padding: 35px;
  border-radius: 60px;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: 'space-between';
}

.FlipCard-content.back p {
  color: white;
  font-size: 2rem;
}


.FlipCard-content.back p.FlipCard-subtitle {
  font-size: 1.25rem;
  color: white;
  text-align: center;
}

.FlipCard-content.back p.FlipCard-text {
  font-size: 0.90rem;
  color: white;
  text-align: center;
}



@media (max-width: 960px) {
  .FlipCard {

    width: 200px;
    height: 200px;

  }


  .FlipCard-content.back {

    width: 200px;
    height: 200px;

  }

  .FlipCard-content.back p.FlipCard-subtitle {
    font-size: 1rem;
    color: white;
    text-align: center;
  }
  
  .FlipCard-content.back p.FlipCard-text {
    font-size: 0.5em;
    color: white;
    text-align: center;
  }


  
}
