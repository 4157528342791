
.FAQ {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
}


.CajaFAQ {
  background-color: #bfbfbf;
  color: black;
  padding: 2%;
  border-radius: 10px; 
  width: 50%; /* Agregamos para que la imagen no se salga de su contenedor */

 
}





.CajaFAQ h3 {
  color: black;
}

.CajaFAQ span {
  font-size: 15px;
  color: black;
}



@media (max-width: 960px) {
  .CajaFAQ {

    width: 90%;
  
   
  }
  
  }