.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.section-texto-container {
  width: 50%;
  z-index: 1;
  text-align: center;
}

.section-texto {
  max-width: 100%;
  max-height: 100%;
}



.h2-texto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Agregamos para que el contenedor abarque todo el ancho */
  height: 100%;
}
