.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.section-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Reducimos la altura para que no ocupe toda la pantalla */
  max-width: 100%; /* Agregamos para que la imagen no se salga de su contenedor */
}

.section-image {
  max-width: 100%;
  max-height: 100%;
}

.h2-imagen {
  padding: 50px 0;
}

.h2-imagen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Agregamos para que el contenedor abarque todo el ancho */
  height: 100%;
}
