html,
body {
  height: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100%;
}