@media (max-width: 960px) {
  .MuiGrid-item {
    flex-basis: 50%;
  }
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
}


.image {

  max-width: 25%;
  height: auto;
  margin-bottom: 20px;
}

/* Estilo para el contenedor principal */
.container {
  background-color: #333; /* Color de fondo */
  min-width: 100%; /* Ancho mínimo del 100% del viewport */
  padding: 2%; /* Relleno */
}

/* Estilo para el título */
.title {
  color: white; /* Color del texto */
  text-align: center; /* Alineación del texto al centro */
}

/* Estilo para el contenedor de la cuadrícula */
.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrar los elementos en el eje horizontal */
  gap: 20px; /* Espacio entre los elementos de la cuadrícula */
}

/* Estilo para los elementos de la cuadrícula */
.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px; /* Relleno */
  background-color: #fff; /* Color de fondo de los elementos */
  border-radius: 8px; /* Borde redondeado */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); /* Sombra */
}

/* Estilo para las imágenes dentro de los elementos de la cuadrícula */
.grid-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* Estilo para el subtítulo dentro de los elementos de la cuadrícula */
.subtitle {
  color: #333; /* Color del texto */
  font-size: 18px; /* Tamaño del texto */
  margin-top: 10px; /* Margen superior */
}
