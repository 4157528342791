.hero {
    position: relative;
    height: 100vh;
    background-color: black;
  }
  
  .banner-container {
    height: 100%;
    overflow: hidden;
  }
  
  .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
    filter: grayscale(0%);
  }
  
  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
  }
  
  .banner-content h1 {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  
  .banner-content .small-text {
    font-size: 12px;
    line-height: 1.2em;
    color: #fff;
  }
  
  .banner-content .big-text {
    font-size: 48px;
    line-height: 1em;
    color: #fff;
  }
  
.banner-button-container{

    margin-top: 1rem;

}

  .MuiButton-contained {
    margin-top: 2rem;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;    
    border-radius: 8px;
  }