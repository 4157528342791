.slide-enter {
  opacity: 0;
  position: absolute;
  z-index: 1;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
