.section-container {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-bottom: 2rem;
  width: 100%
}

.section-title {
  font-size: 2rem;
  text-align: center;
}

.ZigZag-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh; /* Reducimos la altura para que no ocupe toda la pantalla */
  width: 50%; /* Agregamos para que la imagen no se salga de su contenedor */
  padding: 1rem;
}
.section-text-container {
  display: flex;
  align-items: center;
  flex-direction:column;
  height: 40vh; /* Reducimos la altura para que no ocupe toda la pantalla */
  width: 50%; /* Agregamos para que la imagen no se salga de su contenedor */
  padding-right: 10vh;
  padding-left: 10vh;
}

.section-image {
  max-width: 100%;
  max-height: 100%;
}

.h2-imagen {
  padding: 50px 0;
}




.container-zigzag-left {
  display: flex;
  flex-direction:row;
  align-items: center;
  color: white;
  padding-bottom: 2rem;
  padding-top: 2rem;

  width: 80%;
  border-radius: 10px; /* Ajusta el valor según tus preferencias */
  margin: 10px;

}

.gradient-left{
  background: linear-gradient(60deg, #ec3642  50%, #333333 50%);


}


.gradient-right{
  background: linear-gradient(300deg, #ec3642  50%, #333333 50%);


}


.lista {
  list-style-type: square; /* Cambia el tipo de viñeta a cuadrados */
  font-family: Arial, sans-serif; /* Cambia la fuente del texto */
  margin-left: 20px; /* Agrega un margen a la izquierda de la lista */
}

/* Estilos para los elementos de lista */
.lista li {
  font-size: 18px; /* Cambia el tamaño del texto de cada elemento de lista */
  margin-bottom: 20px; /* Agrega espacio entre los elementos de lista */
}




@media (max-width: 960px) {
  .container-zigzag-left {
    display: flex;
    flex-direction:column-reverse !important; 
    height:50% !important;
  }
  .section-text-container {
    height: 10vh !important;

}
.ZigZag-image-container {
  width: 90% !important;

}
.section-text-container span {

    display: none;
  
   
  }
  
}
